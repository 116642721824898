<template>
  <kendo-window ref="kendoWindowRef"
                  :title="mode === 'NEW' ? '코드추가' : '코드수정'"
                  :modal="true"
                  :width="'900px'"
                  style="z-index: 10004; transform: scale(1); opacity: 1; display:none;"
                  @activate="onActivate"
                  @close="onClose"
  >
    <div class="pop-container">

        <!-- //test -->
        <!-- <b>value ::: {{ JSON.stringify(value) }}</b><br/>
        <b>cdId ::: {{ cdId }}</b><br/><br/>
        <br/><br/> -->

        <ul class="filter_list">
          <li>
            <dd>
              <label
                >코드
                <span class="forg">*</span>
              </label>
              <ul class="w70">
                <!-- <button :disabled="isEditMode"
                        @click="checkCdIdDuplicate"
                        class="mid_btn orange w30 f_r mgl10">
                  중복체크
                </button> -->
                <kendo-maskedtextbox  ref="cdIdRef"
                                      mask="000000"
                                      class="w65"
                                      :disabled="isEditMode"
                                      :value="value['cdId']">
                </kendo-maskedtextbox>
              </ul>
            </dd>
            <dd>
              <label
                >코드명
                <span class="forg">*</span>
              </label>
              <ul class="w70">
                <input  ref="cdNmRef"
                        maxlength="300"
                        class="k-textbox w100"
                        v-model.trim="value['cdNm']"/>
              </ul>
            </dd>
          </li>
          
          <li>
            <dd class="w100">
              <label class="w9_5"
                >코드값
              </label>
              <ul class="w85">
                <li>
                  <input  ref="cdValueRef"
                          maxlength="600"
                          class="k-textbox w100"
                          v-model.trim="value['cdValue']"/>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label
                >사용여부
                <span class="forg">*</span>
              </label>
              <ul>
                <li >
                  <kendo-switch ref="useYnRef"
                                :checked="isNewMode || value['useYn'] === 'Y'">
                  </kendo-switch>
                </li>
              </ul>
            </dd>
            <dd>
              <label
                >조회순서
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <kendo-numerictextbox ref="seqNoRef"
                                      class="w80"
                                      :format="'#'"
                                      :min="1"
                                      :max="100000"
                                      v-model.trim="value['seqNo']">
                </kendo-numerictextbox>
              </ul>
            </dd>
          </li>
          <li>
            <dd class="w100">
              <label class="w9_5"
                >설명
              </label>
              <ul class="w85">
                <li>
                  <input  ref="cdDescRef"
                          maxlength="600"
                          class="k-textbox w100"
                          v-model.trim="value['cdDesc1']"/>
                </li>
              </ul>
            </dd>
          </li>
        </ul>
        <div class="button_area w30">
          <ul>
            <li>
              <button type="submit"
                      class="large_btn orange"
                      @click="submit">
                저장
              </button>
            </li>
            <li>
              <button class="large_btn" @click="e => {
                this.widget().close()
              }">
                취소
              </button>
            </li>
          </ul>
        </div>
    </div>
  </kendo-window>
</template>

<script>
import ApiUtil from "@/api/api.util";
import ApiConfig from "@/api/api.config";
import EtnersCommonUtil from "@ebmp-fe-common-module/module/src/common/etners.common.util"

export default {
  name: 'CodeDetailSaveWindow',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'NEW',
      validator: function(value) {
        return ['NEW', 'EDIT'].indexOf(value) !== -1
      }
    },
    value: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isNewMode: function() { return this.$props.mode === 'NEW' },
    isEditMode: function() { return this.$props.mode === 'EDIT' },
  },
  methods: {
    onActivate(e) {
      const vm = this

      if(vm.isNewMode) {

        vm.value.seqNo = 1

        // 신규인 경우, next new cdId 코드 조회하여 기본 셋팅
        ApiUtil.query(`${ApiConfig.efsDomain}/efs/module/code/max-code`, { cdType: 'cdId', cdGrup: vm.value.cdGrup }).then(function (response) {
          if(response.data.resultStatus.messageCode === '2000') {
  
            // vm.cdId = response.data?.resultData?.cdId
            vm.$refs.cdIdRef.kendoWidget().value(response.data?.resultData?.cdId)

          } else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        })
      }
    },
    onClose(e) {

      this.$emit('close')
    },
    /* // 코드 중복체크
    checkCdIdDuplicate(e) {
      // TODO: 

    }, */
    getCdId() {

      return this.$refs.cdIdRef.kendoWidget().value().replace(/_/gi, '').trim()

    },
    // validation
    validator(){
      const vm = this

      // 코드
      if(!vm.getCdId()) {
        kendo.alert('코드를 입력해주세요.').bind('close', function() {
          vm.$refs.cdIdRef.kendoWidget().element.focus()
        })
        return false
      }
      // 코드명
      if(!vm.value['cdNm']) {
        kendo.alert('코드명을 입력해주세요.').bind('close', function() {
          vm.$refs.cdNmRef.focus()
        })
        return false
      }
      // 조회순서
      if(!vm.value['seqNo']) {
        kendo.alert('조회순서를 입력해주세요.').bind('close', function() {
          vm.$refs.seqNoRef.kendoWidget().element.focus()
        })
        return false
      }

      return true
    },
    // 저장
    submit() {
      const vm = this

      if(vm.validator()) {

        let { cdGrup, cdNm, cdId, cdDesc1, cdValue, seqNo } = vm.value
        let params = {
          cdNm,
          cdId: cdId ?? vm.getCdId(),
          cdDesc1,
          cdValue,
          seqNo,
          useYn: vm.$refs.useYnRef.kendoWidget().value() ? 'Y' : 'N'
        }

        if(vm.isNewMode) {
          ApiUtil.post(`${ApiConfig.efsDomain}/efs/module/code/${cdGrup}`, params).then(response => successApi(response))
        }
        if(vm.isEditMode) {
          ApiUtil.put(`${ApiConfig.efsDomain}/efs/module/code/${cdGrup}/${cdId}`, params).then(response => successApi(response))
        }
      }

      function successApi(response) {

        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        kendo.alert('저장되었습니다.').bind('close', function() {
          vm.$store.dispatch('initSearchDataForCodeRequest')
          vm.widget().close()
        })
      }

    },
    widget(){
      const vm = this
      let methods = {
        open: function() {
          vm.$refs.kendoWindowRef.kendoWidget().center().open()
        },
        close: function() {
          vm.$refs.kendoWindowRef.kendoWidget().close()
        }
      }
      return methods
    },
  },
  data: function () {

    return {}
  },
};
</script>

<style scoped>
</style>
